<template>
    <div>
        <el-radio-group v-model="status" size="mini" @change="orderChange">
            <el-radio-button v-for="r in countList" :key="r.rStatus" :label="r.rStatus">{{ r.rStatusStr }}（{{ r.count }}）</el-radio-button>
        </el-radio-group>

        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="车牌号" prop="plateNum">
                <el-input v-model="form.plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="车辆类型" prop="vType">
                <el-select v-model="form.vType">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option v-for="t in vTypeList" :key="t.iValue" :label="t.iValue" :value="t.rValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车辆品牌" prop="vendor">
                <el-input v-model="form.vendor"></el-input>
            </jy-query-item>
            <jy-query-item label="车辆型号" prop="model">
                <el-input v-model="form.model"></el-input>
            </jy-query-item>
            <jy-query-item label="燃料种类" prop="energyT">
                <el-select v-model="form.energyT">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="t in energyTList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="调度发车日期" prop="planT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.planT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesDispatchTeamList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesDispatchTeamReset')">重置</el-button>
                <el-row>
                    <export-excel
                        v-if="btnexist('wastesDispatchTeamExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/vehicle/team/export"
                        :params="form"
                        fileName="车队"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="车牌号" prop="plateNum" show-overflow-tooltip></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa" show-overflow-tooltip></jy-table-column>
                <jy-table-column label="车辆类型" prop="vType" width="100">
                    <template v-slot="scope">{{ formatVType(scope.row.vType) }}</template>
                </jy-table-column>
                <jy-table-column label="当前状态">
                    <template v-slot="scope">{{ formatStatus(scope.row.rStatus) }}</template>
                </jy-table-column>
                <jy-table-column v-if="status != '0'" label="调度发车时间" prop="pTime" width="150">
                    <template v-slot="scope">{{ scope.row.scheduleOrder ? scope.row.scheduleOrder.pTime : "-" }}</template>
                </jy-table-column>
                <jy-table-column v-if="['', '2'].includes(status)" label="实际发车时间" prop="rTime" width="150">
                    <template v-slot="scope">{{ scope.row.scheduleOrder ? scope.row.scheduleOrder.rTime : "-" }}</template>
                </jy-table-column>
                <jy-table-column label="最近定位位置" width="150"></jy-table-column>
                <jy-table-column label="定位时间" width="150"></jy-table-column>
                <jy-table-column label="车辆品牌" prop="vendor"></jy-table-column>
                <jy-table-column label="车辆型号" prop="model"></jy-table-column>
                <jy-table-column label="燃料种类" prop="energyT"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mounted() {
            this.getList();
        },
        mixins: [btnMixins],
        methods: {
            setOperateList() {
                let l = [
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesDispatchTeamView");
                        }
                    },
                    {
                        name: "查看调度单",
                        icon: "el-icon-edit-outline",
                        fun: this.handleDispatchView,
                        isShow: row => {
                            return this.btnexist("wastesDispatchTeamViewSch") && (row.rStatus == "1" || row.rStatus == "2");
                        }
                    }
                ];
                this.operateList = l;
            },
            handleView(row) {
                this.$emit("switch", {
                    ...row,
                    status: this.status
                });
            },
            handleDispatchView(row) {
                this.$router.push({
                    name: "dOrder",
                    params: {
                        scheduleId: row.scheduleOrder ? row.scheduleOrder.scheduleId : ""
                    }
                });
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            formatVTypeValue(val) {
                return val === null ? null : Number(val);
            },
            getList() {
                this.loading = true;
                let url = "/vehicle/team/queryPage";
                this.$http
                    .post(url, {
                        vehicleInfo: {
                            ...this.form,
                            vType: this.formatVTypeValue(this.form.vType)
                        },
                        pTimeStart: this.$util.addTime(this.form.planT && this.form.planT[0], true),
                        pTimeEnd: this.$util.addTime(this.form.planT && this.form.planT[1]),
                        rStatus: this.status,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize
                    })
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.vehicleAttach,
                                ...v.vehicleInfo,
                                ...v.sysOrg,
                                ...v.vehicleEx,
                                scheduleOrder: v.scheduleOrder
                            };
                        });
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            orderChange() {
                this.getList();
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            formatStatus(val) {
                const s = this.countList.find(v => v.rStatus === val);
                return s.rStatusStr;
            },
            formatVType(type) {
                const t = this.vTypeList.find(v => v.rValue == type);
                if (t) {
                    return t.iValue;
                }
                return "-";
            }
        },
        created() {
            this.setOperateList();
        },
        computed: {
            countList() {
                return this.$parent.countList;
            },
            vTypeList() {
                return this.$parent.vTypeList;
            },
            energyTList() {
                return this.$parent.energyTList;
            }
        },
        data() {
            return {
                form: {
                    vType: null,
                    plateNum: "",
                    orgId: "",
                    orgNa: "",
                    vendor: "",
                    model: "",
                    energyT: "",
                    planT: []
                },
                loading: false,
                status: "1",
                dataList: [],
                total: 0,

                pageIndex: 1,
                pageSize: 10,
                operateList: [],

                btnMenuId: "wastesDispatchTeam"
            };
        },
        components: {
            ExportExcel,
            institutionsTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 15px;
    }
</style>
