<template>
    <transition>
        <component :is="current" @switch="switchPage" ref="page"></component>
    </transition>
</template>

<script>
    import Detail from "./detail.vue";
    import List from "./list.vue";
    export default {
        data() {
            return {
                current: "List",

                unitList: [],
                countList: [],
                vTypeList: [],
                energyTList: [],

                option: null
            };
        },
        async created() {
            let p1 = this.$http.post("/vehicle/team/countList"),
                p2 = this.$http.post("/dict/getDictValuesByDictId", "dictvehicletype"),
                p3 = this.$http.post("/dict/getDictValuesByDictId", "dictfueltype");
            Promise.all([p1, p2, p3])
                .then(res => {
                    const [r1, r2, r3] = res;
                    this.countList = r1.detail;
                    this.vTypeList = r2.detail;
                    this.energyTList = r3.detail;
                })
                .then(() => {
                    console.log(this.$refs.page);
                    this.$refs.page.getList && this.$refs.page.getList();
                });
        },
        methods: {
            switchPage(option) {
                if (this.current === "List") {
                    this.current = "Detail";
                } else {
                    this.current = "List";
                }
                this.option = option;
            }
        },
        components: {
            Detail,
            List
        }
    };
</script>
<style></style>
