<template>
    <div>
        <el-page-header @back="backMain" content="车队管理 > 查看"></el-page-header>
        <el-form :model="model" class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item">
                <div class="head_title">基本信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="车牌号">{{ model.plateNum }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属机构">{{ model.orgNa }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车牌颜色">{{ model.color }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="车辆类型">{{ formatVType(model.vType) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车辆品牌">{{ model.vendor }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车辆型号">{{ model.model }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="燃料种类">{{ model.energyT }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="当前状态">{{ formatStatus(model.status) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车架号">{{ model.vin }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="发动机号">{{ model.engineNo }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="产地">{{ model.orignal }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="总质量">{{ model.wTotal }}kg</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="整备质量">{{ model.wCurb }}kg</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="核定载质量">{{ model.wLoad }}kg</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="外型尺寸">{{ model.sizeT }}（mm）</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="货厢尺寸">{{ model.sizeBox }}（mm）</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车辆容积">{{ model.volume }}方</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="准乘人数">{{ model.pasNu }}人</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="备注">{{ model.remark }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">证件信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="营运证">
                            <com-img :src="model.opLiceUrl"></com-img>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="行驶证">
                            <com-img :src="model.regUrl"></com-img>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车辆照片">
                            <com-img :src="model.vehicleUrl"></com-img>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">运输记录</div>
                <jy-table :data="dataList">
                    <jy-table-column type="index" label="序号"></jy-table-column>
                    <jy-table-column label="调度单号" prop="scheduleId">
                        <template v-slot="scope">{{ scope.row.scheduleOrder ? scope.row.scheduleOrder.scheduleId : "" }}</template>
                    </jy-table-column>
                    <jy-table-column label="运输类型">
                        <template v-slot="scope">{{ scope.row.transportT === "1" ? "散货" : "整车" }}</template>
                    </jy-table-column>
                    <jy-table-column label="委托单号">
                        <template v-slot="scope">{{ scope.row.wOrderId }}</template>
                    </jy-table-column>
                    <jy-table-column label="产废企业">
                        <template v-slot="scope">{{ formatUnitInfoName(scope.row.unitInfo) }}</template>
                    </jy-table-column>
                    <jy-table-column label="司机">
                        <template v-slot="scope">{{ scope.row.scheduleOrder | formatDrivers }}</template>
                    </jy-table-column>
                    <jy-table-column label="固废类别" prop="wasteTId">
                        <template v-slot="scope">{{ formatWasteType(scope.row.wasteTId) }}</template>
                    </jy-table-column>
                    <jy-table-column label="固废清运量" prop="weight"></jy-table-column>
                    <jy-table-column label="产废设施地址" prop="addr"></jy-table-column>
                    <jy-table-column label="分拣中心名称">
                        <template v-slot="scope">{{ formatRUnitInfoName(scope.row.scheduleOrder) }}</template>
                    </jy-table-column>
                    <jy-table-column label="抛/重物">
                        <template v-slot="scope">{{ scope.row.nature === "1" ? "抛物" : "重物" }}</template>
                    </jy-table-column>
                    <jy-table-column label="包装方式" prop="pack"></jy-table-column>
                    <jy-table-column label="调度发车时间" prop="pTime">
                        <template v-slot="scope">{{ scope.row.scheduleOrder ? scope.row.scheduleOrder.pTime : "" }}</template>
                    </jy-table-column>
                    <jy-table-column label="实际发车时间" prop="rTime">
                        <template v-slot="scope">{{ scope.row.scheduleOrder ? scope.row.scheduleOrder.rTime : "" }}</template>
                    </jy-table-column>
                    <jy-table-column label="完成运输时间" prop="fTime">
                        <template v-slot="scope">{{ scope.row.scheduleOrder ? scope.row.scheduleOrder.fTime : "" }}</template>
                    </jy-table-column>
                    <jy-table-column label="状态" prop="status">
                        <template v-slot="scope">{{ scope.row.scheduleOrder | formatOrderStatus }}</template>
                    </jy-table-column>
                    <jy-operate v-bind:list="operateList" width="200"></jy-operate>
                </jy-table>
                <jy-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageIndex"
                    :page-size="pageSize"
                    :total="total"
                ></jy-pagination>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        filters: {
            formatDrivers(scheduleOrder) {
                if (scheduleOrder && scheduleOrder.driverList) {
                    return scheduleOrder.driverList.map(v => v.cName).join(",");
                }
                return "-";
            },
            formatOrderStatus(scheduleOrder) {
                if (scheduleOrder) {
                    switch (scheduleOrder.status) {
                        case "0":
                            return "待发车";
                        case "1":
                            return "运输中";
                        case "2":
                            return "已完成";
                        case "3":
                            return "已取消";
                        default:
                            return "-";
                    }
                } else {
                    return "-";
                }
            }
        },
        created() {
            this.initDicts();
        },
        mounted() {
            const option = this.$parent.option;
            this.vehicleId = option.vehicleId;
            this.$http.post("/base/vehicle/queryInfoByVehicleId", this.vehicleId).then(res => {
                this.model = {
                    ...option,
                    ...res.detail
                };
            });
            this.getTransportList();
        },
        methods: {
            initDicts() {
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                });
            },
            formatUnitInfoName(unitInfo) {
                if (unitInfo) {
                    return unitInfo.cName;
                } else {
                    return "-";
                }
            },
            formatRUnitInfoName(scheduleOrder) {
                if (scheduleOrder) {
                    return scheduleOrder.unitInfo.cName;
                } else {
                    return "-";
                }
            },
            formatWasteType(type) {
                const t = this.wasteTypes.find(v => v.wasteTId === type);
                return t ? t.cName : "";
            },
            backMain() {
                this.$emit("switch");
            },
            formatVType(type) {
                const t = this.vTypeList.find(v => v.rValue == type);
                if (t) {
                    return t.iValue;
                }
                return "-";
            },
            formatStatus(val) {
                const s = this.countList.find(v => v.rStatus === val);
                return s ? s.rStatusStr : "-";
            },
            navTo(row) {
                this.$router.push({
                    name: "dOrder",
                    params: {
                        scheduleId: row.scheduleId
                    }
                });
            },
            getTransportList() {
                let url = "/vehicle/team/orderList";
                this.$http
                    .post(url, {
                        vehicleInfo: { vehicleId: this.vehicleId },
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize
                    })
                    .then(res => {
                        this.dataList = res.detail.list;
                        this.total = res.detail.total;
                    });
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getTransportList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getTransportList();
            }
        },
        computed: {
            countList() {
                return this.$parent.countList;
            },
            vTypeList() {
                return this.$parent.vTypeList;
            }
        },
        data() {
            return {
                vehicleId: "",
                model: {},

                dataList: [],
                operateList: [
                    {
                        name: "查看调度单",
                        icon: "el-icon-edit-outline",
                        fun: this.navTo,
                        isShow: row => {
                            return row.rStatus == "1" || row.rStatus == "2";
                        }
                    }
                ],

                total: 0,
                pageIndex: 1,
                pageSize: 10,

                wasteTypes: []
            };
        }
    };
</script>
<style lang="scss" scoped>
    .avatar {
        width: 100px;
        height: 100px;
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }

            .table_bottom_button {
                width: 100%;
                border: 1px dashed #dcdcdc;
                text-align: center;
            }
        }
    }
</style>
